import React from 'react'
import Helmet from 'react-helmet'
import { Layout } from '../layouts/Layout'
import { SEO } from '../components/shared'

const MobileHealthMap = () => (
  <Layout>
    <SEO
      title="Commtel Mobile Coverage Map"
      description="Check network mobile coverage"
    />
    <Helmet>
      <script
        type="text/javascript"
        src="https://opt-au.spatialbuzz.net/cust/6E867DF0/public/init/bootstrap-health-acurus-6E867DF0-D0C6F9B9-iframe-init-outer.js"
      ></script>
      <script
        type="text/javascript"
        src="https://opt-au.spatialbuzz.net/cust/6E867DF0/public/dist/boot-iframe.js"
      ></script>
      <script
        dangerouslySetInnerHTML={{
          __html: `window.onload = function() {
            window.initSBIframe('sb_health');
          };`,
        }}
      />
    </Helmet>

    <main id="mobile-coverage-map" className="single">
      <div className="container">
        <h1 className="page-title text-center">Mobile Status Checker</h1>
      </div>
      <div className="container">
        <div
          id="sb_health"
          className="text-center"
          dangerouslySetInnerHTML={{
            __html: `<p>Unable to load map</p>
            <button class="btn btn-lg btn-accord" onclick="window.initSBIframe('sb_health')">Try Again</button>`,
          }}
        />
      </div>
    </main>
  </Layout>
)
export default MobileHealthMap
